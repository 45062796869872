import React, { useState } from "react";
import logo from "../images/blueberry.png";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [t] = useTranslation("global");
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 50,
      smooth: "easeInOutQuint",
    });
  };

  const { data } = useSelector((state) => state.data);
  const Categories = data.map((e) => e.categories);
  const uniqueCategories = new Set();
  const [activeCategory, setActiveCategory] = useState(null);
  const handleCategoryClick = (categoryName) => {
    setActiveCategory(categoryName === activeCategory ? null : categoryName);
    scrollToTop();
  };

  const { data2 } = useSelector((state) => state.data2);
  const Categories2 = data2.map((e) => e.categories);
  const uniqueCategories2 = new Set();
  const [activeCategory2, setActiveCategory2] = useState(null);
  const handleCategoryClick2 = (categoryName) => {
    setActiveCategory2(categoryName === activeCategory2 ? null : categoryName);
    scrollToTop();
  };
  return (
    <>
      {/* Footer*/}
      <footer>
        {/* Services block*/}
        <div className="py-5 py-lg-6 bg-gray-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-6 py-4 service-column">
                <svg className="svg-icon service-icon">
                  <use xlinkHref="#delivery-time-1"> </use>
                </svg>
                <div className="service-text">
                  <h6 className="text-sm mb-1 font">
                    {t("footer.fast_and_free_delivery")}{" "}
                  </h6>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 py-4 service-column">
                <svg className="svg-icon service-icon">
                  <use xlinkHref="#money-1"> </use>
                </svg>
                <div className="service-text">
                  <h6 className="text-sm mb-1 font">
                    {t("footer.money_back_guarantee")}
                  </h6>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 py-4 service-column">
                <svg className="svg-icon service-icon">
                  <use xlinkHref="#special-price-1"> </use>
                </svg>
                <div className="service-text">
                  <h6 className="text-sm mb-1 font">
                    {t("footer.we_return_money_within")}{" "}
                  </h6>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 py-4 service-column">
                <svg className="svg-icon service-icon">
                  <use xlinkHref="#customer-support-1"> </use>
                </svg>
                <div className="service-text">
                  <h6 className="text-sm mb-1 font">
                    {" "}
                    24/7{t("footer.customer_support")}
                  </h6>
                  <p className="text-muted fw-light text-sm mb-0 font">
                    <a href="tel:07851881818">0785 188 18 18</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Main block - menus, subscribe form*/}
        <div className="py-6 text-muted">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 pe-lg-5 pe-xl-6 mb-5 mb-lg-0  d-flex justify-content-center">
                <img style={{ width: "60%" }} src={logo} alt="blueberry" />
              </div>
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-4">
                    <a
                      className="d-lg-none block-toggler my-3 font"
                      data-bs-toggle="collapse"
                      href="#footerMenu0"
                      aria-expanded="false"
                      aria-controls="footerMenu0"
                    >
                      {t("navbar.pages")}
                      <span className="block-toggler-icon" />
                    </a>
                    {/* Footer collapsible menu*/}
                    <div className="expand-lg collapse" id="footerMenu0">
                      <h6 className="text-dark letter-spacing-1 mb-4 d-none d-lg-block font">
                        {t("navbar.pages")}
                      </h6>
                      <ul className="list-unstyled text-sm pt-2 pt-lg-0">
                        <li className="mb-2">
                          {" "}
                          <Link
                            className="text-muted text-dark-hover link-animated"
                            to="/"
                            onClick={() => {
                              scrollToTop();
                            }}
                          >
                            {t("navbar.home")}
                          </Link>
                        </li>
                        <li className="mb-2">
                          {" "}
                          <Link
                            className="text-muted text-dark-hover link-animated"
                            to="/about"
                            onClick={() => {
                              scrollToTop();
                            }}
                          >
                            {t("navbar.about")}
                          </Link>
                        </li>
                        <li className="mb-2">
                          {" "}
                          <Link
                            className="text-muted text-dark-hover link-animated"
                            to="/contact"
                            onClick={() => {
                              scrollToTop();
                            }}
                          >
                            {t("navbar.contact")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <a
                      className="d-lg-none block-toggler my-3 font"
                      data-bs-toggle="collapse"
                      href="#footerMenu1"
                      aria-expanded="false"
                      aria-controls="footerMenu1"
                    >
                      بلوبيري كرافت
                      <span className="block-toggler-icon" />
                    </a>
                    {/* Footer collapsible menu*/}
                    <div className="expand-lg collapse" id="footerMenu1">
                      <h6 className="text-dark letter-spacing-1 mb-4 d-none d-lg-block font">
                        بلوبيري كرافت
                      </h6>
                      <ul className="list-unstyled text-sm pt-2 pt-lg-0">
                        {Categories.map((e, key) => {
                          if (!uniqueCategories.has(e.categori_name_ar)) {
                            uniqueCategories.add(e.categori_name_ar);
                            return (
                              <li key={key} className="mb-2">
                                <Link
                                  className="text-muted text-dark-hover link-animated"
                                  to={`/shop1/${e.id}`}
                                  onClick={() => handleCategoryClick(e.id)}
                                >
                                  {e[t("card.categori")]}
                                </Link>
                              </li>
                            );
                          }
                          return null;
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <a
                      className="d-lg-none block-toggler my-3 font"
                      data-bs-toggle="collapse"
                      href="#footerMenu2"
                      aria-expanded="false"
                      aria-controls="footerMenu2"
                    >
                      بلوبيري حقائب
                      <span className="block-toggler-icon" />
                    </a>
                    {/* Footer collapsible menu*/}
                    <div className="expand-lg collapse" id="footerMenu2">
                      <h6 className="text-dark letter-spacing-1 mb-4 d-none d-lg-block font">
                        بلوبيري حقائب
                      </h6>
                      <ul className="list-unstyled text-sm pt-2 pt-lg-0">
                        {Categories2.map((e, key) => {
                          if (!uniqueCategories2.has(e.categori_name_ar)) {
                            uniqueCategories2.add(e.categori_name_ar);
                            return (
                              <li key={key} className="mb-2">
                                <Link
                                  className="text-muted text-dark-hover link-animated"
                                  to={`/shop2/${e.id}`}
                                  onClick={() => handleCategoryClick2(e.id)}
                                >
                                  {e[t("card.categori")]}
                                </Link>
                              </li>
                            );
                          }
                          return null;
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Copyright section of the footer*/}
        <div className="py-4 fw-light text-muted">
          <div className="container">
            <div className="row align-items-center text-sm text-gray-500">
              <div className="col-lg-6 text-center text-lg-start">
                <p className="mb-lg-0 font">
                  © 2023,{" "}
                  <a
                    href="http://alayadids.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Alayadi Digital Solutions
                  </a>
                  . All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* /Footer end*/}
    </>
  );
};

export default Footer;
