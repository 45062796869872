const initialAddItemState = {
  loading: false,
  item: {},
  error: null,
};

export const reducerAddItem = (state = initialAddItemState, action) => {
  switch (action.type) {
    case "ADD_ITEM_START":
      return { ...state, loading: true, error: null };
    case "ADD_ITEM_SUCCESS":
      return { ...state, loading: false, item: action.payload };
    case "ADD_ITEM_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const initialAddCommentsState = {
  loading: false,
  comments: {},
  error: null,
};

export const reducerAddComments = (state = initialAddCommentsState, action) => {
  switch (action.type) {
    case "ADD_COMMENTS_START":
      return { ...state, loading: true, error: null };
    case "ADD_COMMENTS_SUCCESS":
      return { ...state, loading: false, comments: action.payload };
    case "ADD_COMMENTS_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const initialAddContactState = {
  loading: false,
  contact: {},
  error: null,
};

export const reducerAddContact = (state = initialAddContactState, action) => {
  switch (action.type) {
    case "ADD_CONTACT_START":
      return { ...state, loading: true, error: null };
    case "ADD_CONTACT_SUCCESS":
      return { ...state, loading: false, contact: action.payload };
    case "ADD_CONTACT_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
