const initialState = {
  doviz: "IQD",
};

// reducer for "lokantaNo"
const DovizReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DOVIZ":
      return {
        ...state,
        doviz: action.doviz,
      };
    default:
      return state;
  }
};

export default DovizReducer;
