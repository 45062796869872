import React, { useEffect, useState } from "react";
import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const Slider = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // تغيير القيمة حسب الحاجة
    };

    // تعيين مراقب لحجم الشاشة
    window.addEventListener("resize", handleResize);

    // التحقق من حجم الشاشة عند تحميل الصفحة
    handleResize();

    // تنظيف المراقب عندما يتم إلغاء تحميل الصفحة
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 50,
      smooth: "easeInOutQuint",
    });
  };
  return (
    <>
      {/* Slider main container */}
      <div
        className="swiper swiper-container home-slider"
        style={{ height: "95vh", minHeight: 600 }}
      >
        {/* Additional required wrapper */}
        <Swiper
          modules={[Navigation, Pagination, A11y, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          autoplay
          pagination={{ clickable: true }}
          className="swiper-wrapper"
        >
          {/* Slide 1 */}
          <SwiperSlide
            className="swiper-slide bg-cover bg-cover-right"
            style={{
              backgroundImage: 'url("img/photo/3321448569.png")',
            }}
          >
            <div className="container-fluid px-lg-6 px-xl-7 h-100">
              <div
                className="row h-100 align-items-center"
                data-swiper-parallax={-500}
              >
                <div className="col-lg-6">
                  <div
                    className={` p-4 ${
                      isSmallScreen ? " bg-bakr" : "bg-transparent"
                    }`}
                  >
                    <p className="subtitle letter-spacing-3 mb-3 text-dark font-weight-light">
                      مرحبًا بك في بلوبيري
                    </p>
                    <h2
                      className=" mb-3 text-primary font"
                      style={{ lineHeight: 1 }}
                    >
                      الفن في الطباعة والتصميم
                    </h2>
                    <p className="text-muted mb-5">
                      {" "}
                      نقدم لكم تشكيلة واسعة من المنتجات بأعلى جودة وأحدث
                      التصاميم.
                    </p>
                    <Link
                      onClick={() => {
                        scrollToTop();
                      }}
                      to="/about"
                      className="btn btn-dark"
                    >
                      استكشف المزيد
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          {/* Slide 2 */}
          <SwiperSlide
            className="swiper-slide bg-cover bg-cover-left"
            style={{
              backgroundImage: 'url("img/photo/55666447.png")',
            }}
          >
            <div className="container-fluid px-lg-6 px-xl-7 h-100">
              <div
                className="row h-100 justify-content-center align-items-center text-center"
                data-swiper-parallax={-500}
              >
                <div className="col-lg-6">
                  <div
                    className={` p-4 ${
                      isSmallScreen ? " bg-bakr" : "bg-transparent"
                    }`}
                  >
                    <p className="subtitle letter-spacing-3 mb-3 text-dark font-weight-light">
                      في بلوبيري، نقدم لك تجربة فريدة
                    </p>
                    <h2
                      className=" mb-3 font text-primary"
                      style={{ lineHeight: 1 }}
                    >
                      نحن نقدم حلاً فنيًا وإبداعيًا لاحتياجات الطباعة والتصميم
                    </h2>
                    <Link
                      onClick={() => {
                        scrollToTop();
                      }}
                      to="/shop1"
                      className="btn btn-dark"
                    >
                      ابدأ التسوق
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          {/* Slide 3 */}
          <SwiperSlide
            className="swiper-slide bg-cover bg-cover-left "
            style={{
              backgroundImage: 'url("img/photo/588.png")',
            }}
          >
            <div className="container-fluid px-lg-6 px-xl-7 h-100">
              <div
                className="row h-100 align-items-center"
                data-swiper-parallax={-500}
              >
                <div className="col-lg-6 offset-lg-6 ">
                  <div
                    className={` p-4 ${
                      isSmallScreen ? " bg-bakr" : "bg-transparent"
                    }`}
                  >
                    <p className="subtitle letter-spacing-3 mb-3  font-weight-light mb-4">
                      بلوبيري أيضا تقدم
                    </p>
                    <h2
                      className=" mb-5 font text-primary"
                      style={{ lineHeight: 1 }}
                    >
                      جنط صغيرة مثالية للسفر{" "}
                    </h2>
                    <Link
                      onClick={() => {
                        scrollToTop();
                      }}
                      to="/shop2"
                      className="btn btn-dark"
                    >
                      ابدأ التسوق
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default Slider;
