// actions.js
import axios from "axios";
import { Url } from "../types/url";

export const getData = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_DATA_START" });
    try {
      const res = await axios.get(Url + "/api/index/1");
      dispatch({ type: "GET_DATA_SUCCESS", payload: res.data });
    } catch (error) {
      dispatch({ type: "GET_DATA_ERROR", payload: error.message });
    }
  };
};

export const getData2 = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_DATA2_START" });
    try {
      const res = await axios.get(Url + "/api/index/2");
      dispatch({ type: "GET_DATA2_SUCCESS", payload: res.data });
    } catch (error) {
      dispatch({ type: "GET_DATA2_ERROR", payload: error.message });
    }
  };
};

export const getMainc = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_MAINC_START" });
    try {
      const res = await axios.get(Url + "/api/maincategory");
      dispatch({ type: "GET_MAINC_SUCCESS", payload: res.data });
    } catch (error) {
      dispatch({ type: "GET_MAINC_ERROR", payload: error.message });
    }
  };
};

export const getContact = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_CONTACT_START" });
    try {
      const res = await axios.get(Url + "/api/yorumlar");
      dispatch({ type: "GET_CONTACT_SUCCESS", payload: res.data });
    } catch (error) {
      dispatch({ type: "GET_CONTACT_ERROR", payload: error.message });
    }
  };
};

export const getColor = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_COLOR_START" });
    try {
      const res = await axios.get(Url + "/api/index");
      dispatch({ type: "GET_COLOR_SUCCESS", payload: res.data.detail });
    } catch (error) {
      dispatch({ type: "GET_COLOR_ERROR", payload: error.message });
    }
  };
};

export const getPrice = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_PRICE_START" });
    try {
      const res = await axios.get(
        "https://api.currencyfreaks.com/v2.0/rates/latest?apikey=f46b5d7abd714cd284838bcfb7dd1e7a"
      );
      dispatch({ type: "GET_PRICE_SUCCESS", payload: res.data.rates });
    } catch (error) {
      dispatch({ type: "GET_PRICE_ERROR", payload: error.message });
    }
  };
};
