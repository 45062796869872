import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Contact = () => {
  const [t] = useTranslation("global");
  return (
    <>
      <section
        className="hero hero-image light-overlay"
        data-parallax="scroll"
        data-image-src="../img/photo/maarten-deckers-0-frPETzEVs-unsplash.jpg"
        data-speed="0.5"
        data-position-x="center"
        data-position-y="center"
      >
        <img
          className="bg-image"
          src="../img/photo/maarten-deckers-0-frPETzEVs-unsplash.jpg"
          alt=""
        />

        <div className="container overlay-content">
          {/* Breadcrumbs */}
          <ol className="breadcrumb ps-0 text-dark">
            <li className="breadcrumb-item">
              <Link to="/">{t("navbar.home")}</Link>
            </li>
            <li className="breadcrumb-item active">{t("navbar.contact")}</li>
          </ol>
          {/* Hero Content */}
          <div className="hero-content">
            <h1 className="hero-heading font">{t("navbar.contact")}</h1>
          </div>
        </div>
      </section>
      <section className="pt-6">
        <div className="container">
          <div className="row">
            <div className="col-md-3 text-center text-md-start mb-4 mb-md-0">
              <h4 className="font">{t("contact.main_store_address")}</h4>
              <p className="text-muted">بغداد، العراق</p>
            </div>
            <div className="col-md-3 text-center text-md-start mb-4 mb-md-0">
              <h4 className="font">{t("contact.phone_numbers")}</h4>
              <p className="text-muted">
                بلوبيري كرافت <a href="tel:07851881818">0785 188 18 18</a>
              </p>
              <p className="text-muted">
                بلوبيري حقائب <a href="tel:07848484847">0784 848 48 47</a>
              </p>
            </div>
            <div className="col-md-3 text-center text-md-start">
              <h4 className="font">{t("contact.email_addresses")}</h4>
              <ul className="list-unstyled text-muted">
                <li>info@blue-berry.shop</li>
              </ul>
            </div>
            <div className="col-md-3 text-center text-md-start">
              <h4 className="font">تابعنا على</h4>
              <ul className="list-inline h3 mb-6 mb-lg-0">
                <li className="list-inline-item m-4">
                  <a href="">
                    <i className="fab fa-facebook-f"> </i>
                  </a>
                </li>
                <li className="list-inline-item m-4">
                  <a href="">
                    <i className="fab fa-twitter"> </i>
                  </a>
                </li>
                <li className="list-inline-item m-4">
                  <a href="">
                    <i className="fab fa-instagram"> </i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container">
          <div className="row">
            <div dir="rtl" className="col-lg-8 col-xl-6 ms-auto">
              <form
                className="form"
                id="contact-form"
                method="post"
                action="contact.php"
              />
              <div className="row">
                <div className="col-sm-6">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="nameContact">
                      الاسم الاول *
                    </label>
                    <input
                      className="form-control form-control-underlined"
                      type="text"
                      name="nameContact"
                      id="nameContact"
                      required="required"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="surnameContact">
                      اللقب *
                    </label>
                    <input
                      className="form-control form-control-underlined"
                      type="text"
                      name="surnameContact"
                      id="surnameContact"
                      required="required"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="emailContact">
                  البريد الاكتروني *
                </label>
                <input
                  className="form-control form-control-underlined"
                  type="email"
                  name="emailContact"
                  id="emailContact"
                  required="required"
                />
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="messageContact">
                  الرسالة *
                </label>
                <textarea
                  className="form-control form-control-underlined"
                  rows={4}
                  name="messageContact"
                  id="messageContact"
                  required="required"
                  defaultValue={""}
                />
              </div>
              <button className="btn btn-outline-primary mt-3" type="submit">
                أرسال{" "}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
