import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { addItem } from "../../../context/actions/postaction";

const Checkout = () => {
  const { price } = useSelector((state) => state.price);
  const { doviz } = useSelector((state) => state.doviz);
  const Para = price[doviz];
  const navigate = useNavigate();
  const [Eroor, setEroor] = useState("d-none");
  const [Name, setName] = useState("");
  const [Note, setNote] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [Address, setAddress] = useState("");
  const [city, setCity] = useState();
  const [responseStatus, setResponseStatus] = useState("");
  const [t] = useTranslation("global");
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 1100,
      smooth: "easeInOutQuint",
    });
  };
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { data } = useSelector((state) => state.data);
  const { data2 } = useSelector((state) => state.data2);
  const Data = [...data, ...data2];
  const totalPrice = cart.reduce((total, item) => {
    const product = Data.find((product) => product.id === item.id);
    return (
      total +
      [
        product.in_discount == 1
          ? product.discount_price
          : product.product_price,
      ] *
        item.count
    );
  }, 0);

  const generateRandomCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let code = "";
    const codeLength = 8;

    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }

    return code;
  };

  const handleSubmit = async () => {
    if (!Name || !Note || !Email || !Phone || !Address || !city) {
      setEroor("");
      scrollToTop();
      return;
    }

    const orderCode = generateRandomCode();
    const itemData = {
      items: cart.map((item) => ({
        name: Name,
        email: Email,
        phone: Phone,
        note: Note,
        address: Address,
        city: city,
        id: parseInt(item.id),
        piece: item.count,
        order_code: orderCode,
        color_id: item.colorId,
      })),
    };

    try {
      const response = await dispatch(addItem(itemData.items));
      if (response.status === 200) {
        setResponseStatus("success");
        setName("");
        setNote("");
        setEmail("");
        setAddress("");
        setCity();
        setPhone("");
        navigate(`/confirmed/${orderCode}`);
        scrollToTop();
      } else {
        setResponseStatus("error");
      }
    } catch (error) {
      setResponseStatus("error");
    }

    dispatch({ type: "clearCart" });
  };
  const cargo = city ? (city === "بغداد" ? 4000 : 7000) : 0;
  return (
    <>
      {/* Hero Section*/}
      <section
        className="hero hero-image light-overlay"
        data-parallax="scroll"
        data-image-src="../img/photo/maarten-deckers-0-frPETzEVs-unsplash.jpg"
        data-speed="0.5"
        data-position-x="center"
        data-position-y="center"
      >
        <img
          className="bg-image"
          src="../img/photo/maarten-deckers-0-frPETzEVs-unsplash.jpg"
          alt=""
        />

        <div className="container overlay-content">
          {/* Breadcrumbs */}
          <ol className="breadcrumb ps-0 text-dark">
            <li className="breadcrumb-item">
              <Link to="/">{t("navbar.home")}</Link>
            </li>
            <li className="breadcrumb-item active">الدفع</li>
          </ol>
          {/* Hero Content */}
          <div className="hero-content">
            <h1 className="hero-heading font">الدفع</h1>
          </div>
        </div>
      </section>
      {/* Checkout*/}
      <section className="py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <form action="#">
                <div
                  className={`text-center p-3 ${Eroor}`}
                  style={{
                    backgroundColor: "rgb(240 127 165 / 29%)",
                    borderRadius: "10px",
                  }}
                >
                  <span className=" text-gray-600  ">{t("Eroor")}</span>
                </div>
                {/* Invoice Address*/}
                <h3 className="mb-4 font"> اكتب معلوماتك هنا </h3>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="fullname_invoice">
                      الأسم الكامل
                    </label>
                    <input
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={Name}
                      required
                      className="form-control"
                      type="text"
                      name="fullname_invoice"
                      placeholder="Joe Black"
                      id="fullname_invoice"
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label
                      className="form-label"
                      htmlFor="emailaddress_invoice"
                    >
                      البريد الاكتروني
                    </label>
                    <input
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={Email}
                      required
                      className="form-control"
                      type="text"
                      name="emailaddress_invoice"
                      placeholder="joe.black@gmail.com"
                      id="emailaddress_invoice"
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="phonenumber_invoice">
                      رقم الهاتف
                    </label>
                    <input
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      value={Phone}
                      required
                      className="form-control"
                      type="text"
                      name="phonenumber_invoice"
                      placeholder="Phone Number"
                      id="phonenumber_invoice"
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="phonenumber_invoice">
                      <label className="form-label" htmlFor="fullname_invoice">
                        المحافظة
                      </label>
                    </label>
                    <select
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                      className="form-control "
                      data-bs-toggle="collapse"
                      aria-expanded="false"
                    >
                      <option>أختر...</option>
                      <option value="بغداد">بغداد</option>
                      <option value="دهوك">دهوك</option>
                      <option value="اربيل">اربيل</option>
                      <option value="نينوى">نينوى</option>
                      <option value="السليمانية">السليمانية</option>
                      <option value="كركوك">كركوك</option>
                      <option value="صلاح الدين "> صلاح الدين </option>
                      <option value="ديالى">ديالى</option>
                      <option value="الانبار">الانبار</option>
                      <option value="واسط">واسط</option>
                      <option value="بابل">بابل</option>
                      <option value="كربلاء">كربلاء</option>
                      <option value="ميسان">ميسان</option>
                      <option value="القادسية">القادسية</option>
                      <option value="النجف">النجف</option>
                      <option value="ذي قار "> ذي قار </option>
                      <option value="المثنى">المثنى</option>
                      <option value="البصرة">البصرة</option>
                    </select>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="phonenumber_invoice">
                      العنوان
                    </label>
                    <input
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                      value={Address}
                      required
                      className="form-control"
                      type="text"
                      name="phonenumber_invoice"
                      placeholder="123 Main St."
                      id="phonenumber_invoice"
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="street_invoice">
                      الملاحظة
                    </label>
                    <input
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                      value={Note}
                      required
                      className="form-control"
                      type="text"
                      name="street_invoice"
                      placeholder="Note"
                      id="street_invoice"
                    />
                  </div>

                  <div className="mb-3 col-12 mt-3">
                    <div className="my-5 d-flex justify-content-between flex-column flex-lg-row d-md-block d-none">
                      <Link className="btn btn-dark" onClick={handleSubmit}>
                        تأكيد
                      </Link>
                    </div>
                  </div>
                </div>

                {/* /Invoice Address*/}
              </form>
            </div>
            <div className="col-lg-4">
              <div className="card mb-5">
                <div className="card-header">
                  <h6 className="mb-0 font">تفاصيل الطلب </h6>
                </div>
                <div className="card-body py-4">
                  <p className="text-muted text-sm">
                    يتم احتساب تكاليف الشحن والتكاليف الإضافية بناءً على القيم
                    التي أدخلتها.
                  </p>
                  <table className="table card-text">
                    <tbody>
                      <tr>
                        <th className="py-4">مجموع الطلب </th>
                        <td className="py-4 text-end text-muted">
                          {parseFloat(totalPrice).toFixed()} {doviz}{" "}
                        </td>
                      </tr>
                      <tr>
                        <th className="py-4">تكلفة التوصيل</th>
                        <td className="py-4 text-end text-muted">
                          {parseInt(cargo)} {doviz}
                        </td>
                      </tr>
                      <tr>
                        <th className="py-4">الضريبة</th>
                        <td className="py-4 text-end text-muted">0.00</td>
                      </tr>
                      <tr>
                        <th className="pt-4 border-0">المجموع</th>
                        <td className="pt-4 text-end h3 fw-normal border-0">
                          {parseFloat(totalPrice + parseInt(cargo)).toFixed()}{" "}
                          {doviz}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="my-5 d-flex justify-content-between flex-column flex-lg-row d-md-none">
                <Link onClick={handleSubmit} className="btn btn-dark">
                  تأكيد
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkout;
