import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import cartReducer from "../reducer/AddToCart";
import {
  reducerGetColor,
  reducerGetContact,
  reducerGetData,
  reducerGetData2,
  reducerGetMainc,
  reducerGetPrice,
} from "../reducer/reducerGetData";
import {
  reducerAddComments,
  reducerAddContact,
  reducerAddItem,
} from "../reducer/reducerPostData";
import noteReducer from "../reducer/Note";
import DovizReducer from "../reducer/Doviz";

const rootReducer = combineReducers({
  cart: cartReducer,
  data: reducerGetData,
  data2: reducerGetData2,
  mainc: reducerGetMainc,
  post: reducerAddItem,
  comments: reducerAddComments,
  contact: reducerGetContact,
  contactpost: reducerAddContact,
  color: reducerGetColor,
  note: noteReducer,
  price: reducerGetPrice,
  doviz: DovizReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
