const initialState = [];

function noteReducer(state = initialState, action) {
  switch (action.type) {
    case "note":
      return action.note;

    default:
      return state;
  }
}

export default noteReducer;
