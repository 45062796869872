import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Card from "./Card";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Shop = () => {
  const { id } = useParams();
  const { data } = useSelector((state) => state.data);
  const [t] = useTranslation("global");

  const options = data.map((e) => e.categories);
  const Categories = [
    {
      id: "all",
      categori_name: "All",
      categori_name_ar: "الكل",
    },
    ...options,
  ];
  const uniqueCategories = new Set();
  const [activeCategory, setActiveCategory] = useState(id);
  const [activeBrand, setActiveBrand] = useState([]);

  const handleCategoryClick = (id) => {
    setActiveCategory(id === activeCategory ? null : id);
  };

  return (
    <div>
      <section
        className="hero hero-image light-overlay"
        data-parallax="scroll"
        data-image-src="../img/photo/maarten-deckers-0-frPETzEVs-unsplash.jpg"
        data-speed="0.5"
        data-position-x="center"
        data-position-y="center"
      >
        <img
          className="bg-image"
          src="../img/photo/maarten-deckers-0-frPETzEVs-unsplash.jpg"
          alt=""
        />

        <div className="container overlay-content">
          {/* Breadcrumbs */}
          <ol className="breadcrumb ps-0 text-dark">
            <li className="breadcrumb-item">
              <Link to="/">{t("navbar.home")}</Link>
            </li>
            <li className="breadcrumb-item active">{t("navbar.products")}</li>
          </ol>
          {/* Hero Content */}
          <div className="hero-content">
            <h1 className="hero-heading font">{t("navbar.products")}</h1>
          </div>
        </div>
      </section>
      <div className="container py-6 ">
        {/* Grid */}
        <div className="products-grid ">
          <div className="product-grid-header">
            <div className="me-3 mb-3">
              <strong className="m-1">{options.length}</strong>
              {t("products.product")}
            </div>

            <div className="mb-3 d-flex align-items-center">
              <span className="d-inline-block me-2">
                {t("products.categories")}
              </span>
              <select
                value={activeCategory}
                className="btn btn-link text-dark ps-0 dropdown-toggle text-decoration-none"
                data-bs-toggle="collapse"
                aria-expanded="false"
                onChange={(e) => handleCategoryClick(e.target.value)}
              >
                {Categories.map((e, key) => {
                  if (!uniqueCategories.has(e.categori_name_ar)) {
                    uniqueCategories.add(e.categori_name_ar);
                    return (
                      <option key={key} value={e.id}>
                        {e[t("card.categori")]}{" "}
                      </option>
                    );
                  }
                  return null;
                })}
              </select>
            </div>
          </div>
          <Card activeCategory={activeCategory} activeBrand={activeBrand} />
        </div>
      </div>
    </div>
  );
};

export default Shop;
