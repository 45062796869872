import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { Navigation, Pagination, A11y } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { animateScroll as scroll } from "react-scroll";
import { Url } from "../../../context/types/url";
import { Link } from "react-router-dom";
const Product = () => {
  const { price } = useSelector((state) => state.price);
  const { doviz } = useSelector((state) => state.doviz);
  const Para = price[doviz];
  const [t] = useTranslation("global");
  const { data } = useSelector((state) => state.data);
  const { data2 } = useSelector((state) => state.data2);
  const Data = [...data, ...data2];
  const products = Data.slice(0, 6);
  const [id, setId] = useState();
  const [colorId, setColorId] = useState();
  const dispatch = useDispatch();
  const [imageId, setImageId] = useState();
  const productsInfo = Data.find((i) => i.id === parseInt(id));
  const color = productsInfo ? productsInfo.colors : null;
  const productsImages = productsInfo
    ? productsInfo.images.find((i) => i.id == imageId)
    : null;
  const filtirColor = productsInfo
    ? color.filter((e) => e.product_code == productsInfo.product_code)
    : null;

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 1100,
      smooth: "easeInOutQuint",
    });
  };
  const InfoData = Data.filter((i) => i.id == id);
  const comments = InfoData.reduce(
    (acc, comment) => [...acc, ...comment.comments],
    []
  );
  const totalRate = comments.reduce(
    (acc, comment) => parseInt(acc) + parseInt(comment.c_rate),
    0
  );
  const averageRate = totalRate / comments.length;
  const decimalPart = averageRate.toFixed(1);
  // const StarRating = (
  //   <ul className="list-inline me-2 mb-0">
  //     <li className="list-inline-item me-0">
  //       {[1, 2, 3, 4, 5].map((index) => (
  //         <i
  //           key={index}
  //           className={`fa fa-star text-${
  //             index <= Math.floor(decimalPart) ? "primary" : "muted"
  //           }`}
  //         />
  //       ))}
  //     </li>
  //   </ul>
  // );
  return (
    <>
      <div className="container-fluid container-fluid-px py-6">
        <div className="row">
          <div className="col-lg-10 col-xl-8 text-center mx-auto">
            <h2 className=" mb-5 font"> أخر المنتجات</h2>
            <p className="lead text-muted mb-6 font">
              نحن فخورون بتقديم أحدث منتجاتنا التي تجمع بين التصميم الفريد
              والجودة العالية. تحقق من أحدث مجموعة لدينا وكن أول من يكتشف أحدث
              صيحات الموضة والتصميم.
            </p>
          </div>
        </div>
        <div className="row">
          {/* product*/}
          {products.map((e, key) => {
            const InfoData = Data.filter((i) => i.id == e.id);
            const comments = InfoData.reduce(
              (acc, comment) => [...acc, ...comment.comments],
              []
            );
            const totalRate = comments.reduce(
              (acc, comment) => parseInt(acc) + parseInt(comment.c_rate),
              0
            );
            const averageRate = totalRate / comments.length;
            const decimalPart = averageRate.toFixed(1);

            // const StarRating = (
            //   <div className="product-stars text-xs">
            //     {[1, 2, 3, 4, 5].map((index) => (
            //       <i
            //         key={index}
            //         className={`fa fa-star text-${
            //           index <= Math.floor(decimalPart) ? "primary" : "muted"
            //         }`}
            //       />
            //     ))}
            //   </div>
            // );
            return (
              <div key={key} className="col-xl-2 col-lg-3 col-md-4 col-6">
                <div
                  className="product product-type-0"
                  data-aos="zoom-in"
                  data-aos-delay={0}
                >
                  <div className="product-image mb-md-3">
                    {e.in_discount == 1 ? (
                      <div className="product-badge badge bg-secondary">
                        {t("card.discount")}
                      </div>
                    ) : null}

                    <a>
                      <div className="product-swap-image">
                        <img
                          className="img-fluid product-swap-image-front"
                          src={`${Url}/product_imgs/${e.images[0].product_photo}`}
                          alt="product"
                          style={{ height: "200px" }} // يمكنك تعيين ارتفاع الصورة هنا
                        />
                        <img
                          className="img-fluid"
                          src={`${Url}/product_imgs/${e.images[0].product_photo}`}
                          alt="product"
                          style={{ height: "200px" }} // تأكد من تعيين نفس الارتفاع للصورة الثانية
                        />
                      </div>
                    </a>
                    <div className="product-hover-overlay">
                      <Link
                        onClick={() => {
                          dispatch({
                            type: "add",
                            id: e.id,
                            colorId: e.colors[0].id,
                          });
                        }}
                        className="text-dark text-sm"
                      >
                        <svg className="svg-icon text-primary-hover svg-icon-heavy d-sm-none">
                          <use xlinkHref="#retail-bag-1"> </use>
                        </svg>
                        <span className="d-none d-sm-inline">
                          {t("card.add_to_cart")}
                        </span>
                      </Link>
                      <div>
                        <a
                          className="text-dark text-primary-hover text-decoration-none"
                          onClick={() => {
                            setId(e.id);
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#quickView"
                        >
                          <svg className="svg-icon svg-icon-heavy">
                            <use xlinkHref="#expand-1"> </use>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="position-relative">
                    <h3 className="text-base mb-1">
                      <a className="text-dark font">{e[t("card.title")]}</a>
                    </h3>
                    <p className="text-gray-600 text-sm">
                      <span>
                        {e.in_discount == 1
                          ? parseFloat(e.discount_price).toFixed()
                          : parseFloat(e.product_price).toFixed()}{" "}
                        {doviz}{" "}
                      </span>

                      {e.in_discount == 1 ? (
                        <s className="me-2 text-gray-500">
                          {parseFloat(e.product_price).toFixed()}
                        </s>
                      ) : null}
                    </p>
                    {/* {StarRating} */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="row justify-content-center align-items-center mb-4">
          <div className="col-12 col-sm-auto text-center">
            <Link
              onClick={() => {
                scrollToTop();
              }}
              to="/shop1"
              className="btn btn-link px-0"
            >
              {t("home.more_products")}
            </Link>
          </div>
        </div>
        {/* Quickview Modal    */}
        <div
          className="modal fade quickview"
          id="quickView"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <button
                className="btn-close btn-close-absolute btn-close-lg btn-close-rotate"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="modal-body quickview-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="detail-carousel">
                      {productsInfo ? (
                        productsInfo.in_discount == 1 ? (
                          <>
                            <div className="product-badge badge bg-primary">
                              {t("card.discount")}
                            </div>
                          </>
                        ) : null
                      ) : null}
                      <div className="swiper swiper-container quickview-slider">
                        {/* Additional required wrapper*/}
                        <Swiper
                          modules={[Navigation, Pagination, A11y]}
                          spaceBetween={50}
                          slidesPerView={1}
                          navigation
                          pagination={{ clickable: true }}
                          className="swiper-wrapper"
                        >
                          {/* Slides*/}
                          {productsInfo
                            ? productsInfo.images.map((e, i) => (
                                <SwiperSlide key={i} className="swiper-slide">
                                  <img
                                    className="img-fluid"
                                    src={`${Url}/product_imgs/${e.product_photo}`}
                                    alt="Modern Jacket 1"
                                  />
                                </SwiperSlide>
                              ))
                            : null}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 p-lg-5">
                    <h2 className="mb-4 mt-4 mt-lg-1 font">
                      {productsInfo ? productsInfo[t("card.title")] : null}
                    </h2>
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between mb-4">
                      <ul className="list-inline mb-2 mb-sm-0">
                        <li className="list-inline-item h4 fw-light mb-0">
                          {productsInfo
                            ? productsInfo.in_discount == 1
                              ? parseFloat(
                                  productsInfo.discount_price
                                ).toFixed()
                              : parseFloat(productsInfo.product_price).toFixed()
                            : null}{" "}
                          {doviz}
                        </li>
                        <li className="list-inline-item text-muted fw-light">
                          {productsInfo ? (
                            productsInfo.in_discount == 1 ? (
                              <>
                                <del className="text-muted fs-lg me-3">
                                  {parseFloat(
                                    productsInfo.product_price
                                  ).toFixed()}{" "}
                                </del>
                              </>
                            ) : null
                          ) : null}
                        </li>
                      </ul>
                      {/* <div className="d-flex align-items-center text-sm">
                        {StarRating}
                        <span className="text-muted text-uppercase">
                          {comments.length} {t("card.reviews")}
                        </span>
                      </div> */}
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: productsInfo
                          ? productsInfo[t("card.description")]
                          : null,
                      }}
                      className="mb-4 text-muted"
                    ></p>
                    <form id="buyForm_modal" action="#">
                      <div className="row">
                        <div className="col-sm-6 col-lg-12 detail-option mb-5">
                          <h6 className="detail-option-heading font">اللون</h6>
                          <div className="mt-4 mt-lg-0">
                            <ul className="list-inline mb-0 colours-wrapper">
                              {productsInfo
                                ? color.map((e, key) => {
                                    return (
                                      <li
                                        key={key}
                                        className="list-inline-item"
                                      >
                                        <label
                                          className="btn-colour"
                                          htmlFor={e.id}
                                          style={{
                                            backgroundColor: `${e.code} `,
                                          }}
                                          data-allow-multiple=""
                                        >
                                          {" "}
                                        </label>
                                        <input
                                          onClick={() => {
                                            setColorId(e.id);
                                          }}
                                          className="input-invisible"
                                          type="checkbox"
                                          name="colour"
                                          defaultValue="value_sidebar_Blue"
                                          id={e.id}
                                        />
                                      </li>
                                    );
                                  })
                                : null}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="input-group w-100 mb-4">
                        <a
                          className="btn btn-success form-control form-control-lg detail-quantity"
                          target="_blank"
                          href={`https://api.whatsapp.com/send?phone=+9647851881818&text=مرحبًا%0Aأنا أود شراء المنتج ${
                            productsInfo ? productsInfo.product_code : null
                          }%0Aيرجى تقديم المزيد من المعلومات حول المنتج وكيفية الشراء.`}
                          data-action="share/whatsapp/share"
                        >
                          <i className="bi bi-whatsapp align-middle mx-1" />
                          {t("card.quick_view")}
                        </a>
                        <div className="flex-grow-1">
                          <div className="d-grid h-100">
                            <Link
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => {
                                const selectedColorId =
                                  colorId ||
                                  (filtirColor.length > 0
                                    ? filtirColor[0].id
                                    : null);
                                dispatch({
                                  type: "add",
                                  id: productsInfo.id,
                                  colorId: selectedColorId,
                                });
                              }}
                              className="btn btn-secondary p-3 "
                            >
                              {" "}
                              <i className="fa fa-shopping-cart me-2" />
                              {t("card.add_to_cart")}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <ul className="list-unstyled">
                        <li>
                          <strong>الفئة:</strong>{" "}
                          <a className="text-muted">
                            {productsInfo
                              ? productsInfo.categories.categori_name
                              : null}
                          </a>
                        </li>
                        <li>
                          <strong>كود المنتج :</strong>{" "}
                          <a dir="rtl" className="text-muted">
                            {productsInfo ? productsInfo.product_code : null}
                          </a>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
