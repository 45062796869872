import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

const Confirmed = () => {
  const [t] = useTranslation("global");
  const { id } = useParams();
  return (
    <>
      {/* Hero Section*/}
      <section className="hero mt-7 d-flex justify-content-center">
        <div className="container">
          {/* Breadcrumbs */}
          <ol className="breadcrumb ps-0 ">
            <li className="breadcrumb-item">
              <Link>{t("navbar.home")}</Link>
            </li>
            <li className="breadcrumb-item active">تم الطلب </li>
          </ol>
          {/* Hero Content*/}
          <div className="hero-content">
            <h1 className="hero-heading font"> تم الطلب </h1>
            <div>
              <div
                role="alert"
                className="d-flex align-items-center alert alert-success"
              >
                <svg className="svg-icon w-3rem h-3rem svg-icon-light flex-shrink-0 me-3">
                  <use xlinkHref="#checked-circle-1"> </use>
                </svg>
                تم أرسال الطلب بنجاح
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Order confirmed*/}
      <section dir="rtl" className="pb-6">
        <div className="container">
          <p className="lead">{t("Shop_Cart.thank_you_for_your_order_1")}</p>
          <p className="lead mb-5">
            {t("Shop_Cart.thank_you_for_your_order_2")}
          </p>
          <p className="mb-6">
            <a className="btn btn-outline-dark">
              {t("Shop_Cart.thank_you_for_your_order_3")}{" "}
              {t("Shop_Cart.thank_you_for_your_order_4")}
            </a>
          </p>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-6">
                <div className="p-5 bg-gray-100 d-flex justify-content-between">
                  <div className="text-sm text-uppercase text-muted mb-3">
                    رقم الطلب
                  </div>
                  <span className="h5 ">{id}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Confirmed;
