import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const About = () => {
  const [t] = useTranslation("global");
  return (
    <>
      <section className="hero hero-image light-overlay">
        <img
          className="bg-image"
          src="../img/photo/maarten-deckers-0-frPETzEVs-unsplash.jpg"
          alt=""
        />

        <div className="container overlay-content">
          {/* Breadcrumbs */}
          <ol className="breadcrumb ps-0 text-dark">
            <li className="breadcrumb-item">
              <Link to="/">{t("navbar.home")}</Link>
            </li>
            <li className="breadcrumb-item active">{t("navbar.about")}</li>
          </ol>
          {/* Hero Content */}
          <div className="hero-content">
            <h1 className="hero-heading font">{t("navbar.about")}</h1>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row my-lg-3">
            <div className="col-lg-7 col-xl-6 order-lg-2 mb-3 mb-lg-0">
              <div className="d-flex align-items-center p-5 p-xl-6 bg-gray-100 h-100">
                <div dir="rtl">
                  <h6 className="text-uppercase text-primary letter-spacing-5 mb-3 font">
                    {" "}
                    حول شركة
                  </h6>
                  <h1 className="display-3 fw-bold mb-5 font"> بلوبيري</h1>
                  <div className="row">
                    <div className="col-xl-6">
                      <p className="text-lg text-muted mb-lg-0 font">
                        شركة بلوبيري متخصصة في تصميم وطباعة الملصقات والفواصل
                        للكتب، بالإضافة إلى طباعة التصاميم على الأكواب وغيرها من
                        المنتجات. نحن نقدم حلاً فنيًا وإبداعيًا لاحتياجات
                        الطباعة والتصميم، مع التركيز على الجودة والتفاصيل.
                      </p>
                    </div>
                    <div className="col-xl-6">
                      <p className="text-lg text-muted mb-0 font">
                        بلوبيري أيضا تقدم جنط صغيرة مثالية للسفر، تجمع بين
                        الأناقة والوظيفة. نحن نهتم بتلبية احتياجات عملائنا بأعلى
                        مستويات الجودة والتميز. اكتشف معنا عالم الطباعة والتصميم
                        بأسلوب فريد ومبتكر.
                      </p>
                    </div>
                  </div>
                  <hr className="my-5" />
                  <div className="row">
                    <div className="col-xl-6">
                      <h6 className="text-uppercase text-primary letter-spacing-3 mb-4 font">
                        تواصل معنا
                      </h6>
                      <p className="text-muted mb-5 mb-xl-0 font">
                        بغداد، العراق
                        <br />
                        هاتف: <a href="tel:07851881818">07851881818</a>
                      </p>
                    </div>
                    <div className="col-xl-6">
                      <h6 className="text-uppercase text-primary letter-spacing-3 mb-4 font">
                        منتجاتنا
                      </h6>
                      <p className="text-muted mb-0 font">
                        • ملصقات وفواصل للكتب
                        <br />
                        • طباعة على الأكواب
                        <br />• جنط صغيرة للسفر
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-xl-6 pe-lg-0 order-lg-1">
              <div className="dark-overlay mh-full-screen-with-header h-100">
                <img
                  className="bg-image"
                  src="img/photo/raoul-ortega-2JtF6pYAOOI-unsplash.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
