import React, { useEffect, useState } from "react";
import Logo from "../images/blueberry.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { animateScroll as scroll } from "react-scroll";
import { Url } from "../../context/types/url";

const Navbar = () => {
  const { price } = useSelector((state) => state.price);
  const { doviz } = useSelector((state) => state.doviz);
  const Para = price[doviz];
  const [t, i18n] = useTranslation("global");
  const handleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  const [bg, setBg] = useState(false);
  const { data } = useSelector((state) => state.data);
  const Categories = data.map((e) => e.categories);
  const uniqueCategories = new Set();
  const [activeCategory, setActiveCategory] = useState(null);
  const handleCategoryClick = (categoryName) => {
    setActiveCategory(categoryName === activeCategory ? null : categoryName);
    scrollToTop();
  };
  const { data2 } = useSelector((state) => state.data2);
  const Categories2 = data2.map((e) => e.categories);
  const uniqueCategories2 = new Set();
  const [activeCategory2, setActiveCategory2] = useState(null);

  const handleCategoryClick2 = (categoryName) => {
    setActiveCategory2(categoryName === activeCategory2 ? null : categoryName);
    scrollToTop();
  };

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 50,
      smooth: "easeInOutQuint",
    });
  };
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  console.log(cart);
  const Data = [...data, ...data2];
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const handleSearch = () => {
    const searchRegex = new RegExp(searchTerm, "i");

    const searchResultsFromData = data.filter((item) =>
      searchRegex.test(
        item[t("card.title")] &&
          item[t("card.description")] &&
          item.categories.categori_name
      )
    );
    const searchResultsFromData2 = data2.filter((item) =>
      searchRegex.test(
        item[t("card.title")] &&
          item[t("card.description")] &&
          item.categories.categori_name
      )
    );

    const combinedSearchResults = [
      ...searchResultsFromData,
      ...searchResultsFromData2,
    ];
    setSearchResults(combinedSearchResults);
  };

  const totalPrice = cart.reduce((total, item) => {
    const product = Data.find((product) => product.id === item.id);
    return (
      total +
      [
        product.in_discount == 1
          ? product.discount_price
          : product.product_price,
      ] *
        item.count
    );
  }, 0);
  const totalItems = cart.reduce((total, item) => total + item.count, 0);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerClass = ` header-absolute${scrolled ? " scrolled" : ""}`;
  const [close, setClose] = useState(false);

  const Close = () => {
    if (close) {
      setClose(true);
    }
  };

  return (
    <>
      {/* navbar*/}
      <header className={headerClass}>
        {/* Top Bar*/}
        <div className="top-bar text-sm bg-transparent">
          <div className="container-fluid px-lg-5 py-3">
            <div className="row align-items-center">
              <div className="col-6 col-md-6 d-block d-md-block">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item me-2">
                    <svg className="svg-icon me-2">
                      <use xlinkHref="#calls-1"> </use>
                    </svg>
                    <a className="topbar-link" href="tel:07851881818">
                      07851881818
                    </a>
                  </li>
                </ul>
              </div>
              <div className=" col-6 col-md-6 d-block d-flex justify-content-md-end justify-content-between">
                {/* Language Dropdown*/}
                <div className="dropdown border-right px-3">
                  <Link
                    className="dropdown-toggle topbar-link"
                    id="currencyDropdown"
                    data-bs-toggle="dropdown"
                    data-bs-display="static"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {t("language")}
                  </Link>

                  <div
                    className="dropdown-menu dropdown-menu-animated dropdown-menu-end"
                    aria-labelledby="langsDropdown"
                  >
                    <Link
                      className="dropdown-item "
                      onClick={() => {
                        handleLanguage("en");
                      }}
                    >
                      English
                    </Link>
                    <Link
                      className="dropdown-item pb-1"
                      onClick={() => {
                        handleLanguage("ar");
                      }}
                    >
                      العربية
                    </Link>
                  </div>
                </div>
                {/* Currency Dropdown*/}
                <div className="dropdown ps-3 ms-0">
                  <Link
                    className="dropdown-toggle topbar-link"
                    id="currencyDropdown"
                    data-bs-toggle="dropdown"
                    data-bs-display="static"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {doviz}
                  </Link>
                  <div
                    className="dropdown-menu dropdown-menu-animated dropdown-menu-end"
                    aria-labelledby="currencyDropdown"
                  >
                    <Link
                      className="dropdown-item"
                      onClick={() => {
                        dispatch({ type: "DOVIZ", doviz: "IQD" });
                      }}
                    >
                      IQD
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Top Bar End*/}
        <nav
          className={`navbar my-0 py-0  mx-1 navbar-expand-lg ${
            bg === true ? "bg-white" : null
          } bg-transparent border-0 shadow-0 navbar-light px-lg-5 `}
        >
          <button
            onClick={() => {
              setBg(!bg);
            }}
            className="navbar-toggler navbar-toggler-right "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarContent"
            aria-controls="navbarContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg className="svg-icon navbar-icon">
              <use xlinkHref="#menu-hamburger-1"> </use>
            </svg>
          </button>
          <Link className="navbar-brand" to="/">
            <img style={{ width: "50px" }} src={Logo} alt="Blue Berry" />
          </Link>

          <ul className="list-inline mb-0 d-block d-lg-none">
            <li className="list-inline-item position-relative">
              <a
                className="text-dark text-primary-hover"
                data-bs-toggle="modal"
                data-bs-target="#sidebarCart"
              >
                <svg className="svg-icon navbar-icon">
                  <use xlinkHref="#retail-bag-1"> </use>
                </svg>
                {totalItems > 0 ? (
                  <div className="navbar-icon-badge">{totalItems}</div>
                ) : null}
              </a>
            </li>
          </ul>
          <div className="collapse navbar-collapse" id="navbarContent">
            <ul className="navbar-nav mt-3 mt-lg-0">
              <li className="nav-item dropdown ">
                <Link
                  onClick={() => {
                    scrollToTop();
                  }}
                  className="nav-link"
                  to="/"
                >
                  {t("navbar.home")}
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  id="shopDropdown"
                  to="/shop1"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {t("navbar.products")}
                </Link>
                <div
                  className="dropdown-menu dropdown-menu-md dropdown-menu-animated py-0"
                  aria-labelledby="shopDropdown"
                >
                  <div className="row">
                    <div className="col-lg-4 col-sm-6 p-lg-5">
                      <h6 className="dropdown-header h6 ps-lg-0 font">
                        <Link
                          onClick={() => {
                            scrollToTop();
                          }}
                          to="/shop1"
                        >
                          بلوبيري كرافت
                        </Link>
                      </h6>
                      {Categories.map((e, key) => {
                        if (!uniqueCategories.has(e.categori_name_ar)) {
                          uniqueCategories.add(e.categori_name_ar);
                          return (
                            <Link
                              key={key}
                              className="dropdown-item ps-lg-0"
                              to={`/shop1/${e.id}`}
                              onClick={() => handleCategoryClick(e.id)}
                            >
                              {e[t("card.categori")]}
                            </Link>
                          );
                        }
                        return null;
                      })}
                    </div>
                    <div className="col-lg-4 col-sm-6 p-lg-5">
                      <h6 className="dropdown-header h6 ps-lg-0 font">
                        <Link
                          onClick={() => {
                            scrollToTop();
                          }}
                          to="/shop2"
                        >
                          بلوبيري حقائب
                        </Link>
                      </h6>
                      {Categories2.map((e, key) => {
                        if (!uniqueCategories2.has(e.categori_name_ar)) {
                          uniqueCategories2.add(e.categori_name_ar);
                          return (
                            <Link
                              key={key}
                              className="dropdown-item ps-lg-0"
                              to={`/shop2/${e.id}`}
                              onClick={() => handleCategoryClick2(e.id)}
                            >
                              {e[t("card.categori")]}
                            </Link>
                          );
                        }
                        return null;
                      })}
                    </div>
                    <div className="col-lg-4 d-none d-lg-block position-relative">
                      <img
                        className="bg-image"
                        src="img/product/kyle-loftus-596319-unsplash-cropped.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </li>

              <li className="nav-item dropdown">
                <Link
                  onClick={() => {
                    scrollToTop();
                  }}
                  className="nav-link "
                  to="/about"
                >
                  {t("navbar.about")}
                </Link>
              </li>
              {/* Megamenu*/}
              <li className="nav-item dropdown position-static">
                <Link
                  onClick={() => {
                    scrollToTop();
                  }}
                  className="nav-link "
                  to="/contact"
                >
                  {t("navbar.contact")}
                </Link>
              </li>
            </ul>
            <form
              className="d-lg-flex ms-auto me-lg-5 me-xl-6 my-4 my-lg-0"
              action="#"
            >
              <div className="input-group input-group-underlined">
                <input
                  className="form-control form-control-underlined ps-3"
                  type="text"
                  placeholder="البحث"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  aria-label="البحث"
                  aria-describedby="button-search"
                />
                <button
                  className="btn btn-underlined"
                  id="button-search"
                  type="button"
                  onClick={() => handleSearch()}
                >
                  <svg className="svg-icon navbar-icon">
                    <use xlinkHref="#search-1"> </use>
                  </svg>
                </button>
              </div>
            </form>
            <ul className="list-inline mb-0 d-none d-lg-block">
              <li className="list-inline-item position-relative me-3">
                <a
                  className="text-dark text-primary-hover"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#sidebarCart"
                >
                  <svg className="svg-icon navbar-icon">
                    <use xlinkHref="#retail-bag-1"> </use>
                  </svg>
                  {totalItems > 0 ? (
                    <div className="navbar-icon-badge">{totalItems}</div>
                  ) : null}
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      {/* Sidebar Cart Modal*/}
      <div
        className="modal fade modal-right"
        id="sidebarCart"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content sidebar-cart-content">
            <div className="modal-header mb-3 border-0">
              <button
                className="btn-close btn-close-lg btn-close-rotate opacity-8"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body px-5 sidebar-cart-body">
              {/* Empty cart snippet*/}
              {/* In case of empty cart - display this snippet + remove .d-none*/}
              <div className="d-none text-center mb-5">
                <svg className="svg-icon w-3rem h-3rem svg-icon-light mb-4 text-muted">
                  <use xlinkHref="#retail-bag-1"> </use>
                </svg>
              </div>
              {/* Empty cart snippet end*/}
              <div className="sidebar-cart-product-wrapper custom-scrollbar">
                {/* cart item*/}
                {cart.map((e) => {
                  const itemInCart = Data.find((item) => item.id === e.id);
                  return (
                    <div key={itemInCart.id} className="navbar-cart-product">
                      <div className="d-flex align-items-center">
                        <a>
                          <img
                            className="img-fluid navbar-cart-product-image"
                            src={`${Url}/product_imgs/${itemInCart.images[0].product_photo}`}
                            alt="..."
                          />
                        </a>
                        <div className="w-100">
                          <Link
                            onClick={() =>
                              dispatch({
                                type: "remove",
                                id: itemInCart.id,
                                colorId: e.colorId,
                              })
                            }
                            className="navbar-cart-product-remove"
                          >
                            <svg className="svg-icon sidebar-cart-icon">
                              <use xlinkHref="#close-1"> </use>
                            </svg>
                          </Link>
                          <div className="ps-3">
                            {" "}
                            <Link className="navbar-cart-product-link text-dark link-animated">
                              {itemInCart[t("card.title")]}
                            </Link>
                            <small className="d-block text-muted">
                              {t("number")}: {e.count}
                            </small>
                            <strong className="d-block text-sm">
                              {itemInCart.in_discount == 1
                                ? parseFloat(
                                    itemInCart.discount_price
                                  ).toFixed()
                                : parseFloat(
                                    itemInCart.product_price
                                  ).toFixed()}{" "}
                              {doviz}{" "}
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="modal-footer sidebar-cart-footer shadow">
              <div className="w-100">
                <h5 className="mb-4 font">
                  المجموع:{" "}
                  <span className="float-right">
                    {parseFloat(totalPrice).toFixed()} {doviz}{" "}
                  </span>
                </h5>
                <div
                  data-bs-toggle="modal"
                  data-bs-target="#sidebarCart"
                  className="d-grid gap-3"
                >
                  <Link
                    onClick={() => {
                      scrollToTop();
                    }}
                    className="btn btn-dark font"
                    to="/checkout"
                  >
                    الدفع
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
