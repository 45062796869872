import React from "react";
import Categories from "./Categories";
import Product from "./product";
import Slider from "./Slider";
import Discount from "./Discount";
import Brands from "./Brands";

const Home = () => {
  return (
    <>
      <Slider />
      <Categories />
      <Product />
    </>
  );
};

export default Home;
