import React from "react";
import { useSelector } from "react-redux";
import { Url } from "../../../context/types/url";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
const Categories = () => {
  const { mainc } = useSelector((state) => state.mainc);
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 1100,
      smooth: "easeInOutQuint",
    });
  };
  return (
    <>
      {/* Categories big*/}
      <div className="bg-gray-100 position-relative">
        <div className="container py-6">
          <div className="row">
            {mainc.map((e, key) => (
              <div key={key} className="col-sm-6 mb-5 mb-sm-0">
                <div className="card card-scale shadow-0 border-0 text-white text-hover-gray-900 overlay-hover-light text-center">
                  <img
                    className="card-img img-scale"
                    src={`${Url}/main_foto/${e.maincategories_foto}`}
                    alt="Card image"
                  />
                  <div className="card-img-overlay d-flex align-items-center">
                    <div className="w-100 py-3 bg-bakr">
                      <h2 className="display-3 fw-bold mb-0 text-primary font">
                        {e.arabic_name}
                      </h2>
                      <Link
                        onClick={() => {
                          scrollToTop();
                        }}
                        className="stretched-link"
                        to={`shop${e.id}`}
                      >
                        <span className="sr-only">See </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
