import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/layouts/Footer";
import Navbar from "./components/layouts/Navbar";
import Home from "./components/pages/home";
import About from "./components/pages/about";
import Shop from "./components/pages/shop";
import Contact from "./components/pages/contact";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getData,
  getData2,
  getMainc,
  getPrice,
} from "./context/actions/getaction";
import Shop2 from "./components/pages/shop2";
import Checkout from "./components/pages/checkout";
import Confirmed from "./components/pages/checkout/Confirmed";
import "./index.css";
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getData());
    dispatch(getData2());
    dispatch(getMainc());
    dispatch(getPrice());
  }, [dispatch]);

  return (
    <div className="font">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/shop1" element={<Shop />} />
          <Route path="/shop1/:id" element={<Shop />} />
          <Route path="/shop2" element={<Shop2 />} />
          <Route path="/shop2/:id" element={<Shop2 />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/confirmed/:id" element={<Confirmed />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
